import React, { useState } from 'react';

function App() {
  const [tabIndex, setTabIndex] = useState(0);
  const [name, setName] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [signupDetails, setSignupDetails] = useState({
    name: '',
    mobileNo: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSignupDetails({ ...signupDetails, [name]: value });
  };

  const handleImageUpload = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', signupDetails.name);
    formData.append('mobileNo', signupDetails.mobileNo);
    formData.append('email', signupDetails.email);
    formData.append('password', signupDetails.password);
    if (profileImage) {
      formData.append('profileImage', profileImage);
    }

    try {
      const response = await fetch('http://localhost:5000/api/upload', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      console.log(data); // Handle the response from the backend
    } catch (error) {
      console.error('Error uploading:', error);
    }
  };

  return (
    <div>
      <h1>Upload Name and Profile Image</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div>
          <label>Name</label>
          <input type="text" name="name"  value={signupDetails.name} onChange={handleInputChange}/>
          <input type="text" name="mobileNo"  value={signupDetails.mobileNo} onChange={handleInputChange}/>
          <input type="text" name="email"  value={signupDetails.email} onChange={handleInputChange}/>
          <input type="text" name="password"  value={signupDetails.password} onChange={handleInputChange}/>
         
        </div>
        <div>
          <label>Profile Image</label>
          <input type="file" onChange={handleImageUpload} />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default App;
