import axios from 'axios';
import apiurl from '../apiUrl';


// Function to handle GET request with examName and testName
export const fetchTestData = async (exam, examName, testName) => {
  const API_URL = `${apiurl}/quest/test-data?exam=${encodeURIComponent(exam)}&examName=${encodeURIComponent(examName)}&testName=${encodeURIComponent(testName)}`;
  
  try {
    const token = localStorage.getItem('token'); // Get token from localStorage
    const response = await axios.get(API_URL, {
      headers: {
        Authorization: `Bearer ${token}` // Include token in Authorization header
      }
    });

    return response.data; // Return the fetched data
  } catch (error) {
    console.error("Error fetching test data:", error);
    throw error; // Rethrow error for handling
  }
};




// export const fetchTestData1 =
//  [
//     {
//       question: {
//         type: "text",
//         content:
//           "If the median of \\( \\frac{2a}{7}, \\frac{3a}{4}, \\dots \\frac{a}{2} \\) is \\( \\frac{11}{28} \\), then find the value of 'a'.",
//       },
//       options: [
//         { type: "text", content: "4" },
//         { type: "text", content: "1" },
//         { type: "text", content: "2" },
//         { type: "text", content: "3" },
//       ],
//       answer: 0, // 0 corresponds to the correct option ("4")
//     },
//     {
//       question: { type: "text", content: "What is 2 + 2?" },
//       options: [
//         { type: "text", content: "3" },
//         { type: "text", content: "4" },
//         { type: "text", content: "5" },
//         { type: "text", content: "6" },
//       ],
//       answer: 1, // 1 corresponds to the correct option ("4")
//     },
//     {
//       question: { type: "image", content: "path/to/question-image.jpg" },
//       options: [
//         { type: "text", content: "Option 1" },
//         { type: "text", content: "Option 2" },
//         { type: "image", content: "path/to/option-image1.jpg" },
//         { type: "image", content: "path/to/option-image2.jpg" },
//       ],
//       answer: 2, // 2 corresponds to the correct option ("path/to/option-image1.jpg")
//     },
//   ];
  