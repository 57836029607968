import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import "./TestResult.css";
import apiurl from '../apiUrl';

const TestResultPage = ({ language = "en" }) => {
  const { state } = useLocation();
  const { selectedOptions, questionsData = [], examName = "Exam", testName = "Test" } = state || {};

  // State to track which solutions are visible
  const [visibleSolutions, setVisibleSolutions] = useState({});
  const [activeQuestion, setActiveQuestion] = useState(0);

  // Refs for question containers to scroll into view
  const questionRefs = useRef([]);

  useEffect(() => {
    // Scroll to the active question when activeQuestion changes
    if (questionRefs.current[activeQuestion]) {
      questionRefs.current[activeQuestion].scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [activeQuestion]);

  const calculateResult = () => {
    let correctAnswers = 0;
    questionsData.forEach((question, index) => {
      const correctOptionIndex = parseInt(question.answer) - 1; // Convert answer to zero-based index
      if (selectedOptions[index] === correctOptionIndex) {
        correctAnswers++;
      }
    });
    return correctAnswers;
  };

  const result = calculateResult();

  const toggleSolution = (index) => {
    setVisibleSolutions((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the visibility of the solution
    }));
  };

  const handleQuestionClick = (index) => {
    setActiveQuestion(index);
  };

  return (
    <div className="test-result-page-container">
      {/* Sidebar with question numbers */}
      <div className="sidebar">
        <h4>Questions</h4>
        <ul>
          {questionsData.map((_, index) => (
            <li
              key={index}
              className={`sidebar-item ${activeQuestion === index ? "active" : ""}`}
              onClick={() => handleQuestionClick(index)}
            >
              {index + 1}
            </li>
          ))}
        </ul>
      </div>

      {/* Main content area */}
      <div className="test-result-page">
        <header className="test-header">
          <h2>Exam: {examName}</h2>
          <h3>Test: {testName}</h3>
          <h4>Result: {result} / {questionsData.length}</h4>
        </header>

        <div className="test-results">
          {questionsData.map((question, index) => {
            const userAnswerIndex = selectedOptions[index];
            const correctAnswerIndex = parseInt(question.answer) - 1; // Convert answer to zero-based index
            const isCorrect = userAnswerIndex === correctAnswerIndex;
            const isAnswered = userAnswerIndex !== undefined;

            return (
              <div
                key={index}
                className="result-question"
                ref={(el) => (questionRefs.current[index] = el)}
              >
                <div className="question-text">
                  <h5 className="question-numberTR">Question {index + 1}:  </h5>
                  {question.question[language]?.type === "text" ? (
                    <p className="question-content">{question.question[language].content}</p>
                  ) : (
                    <img
                      className="question-image"
                      src={`${apiurl}/${question.question[language].content}`}
                      alt={`Question ${index + 1}`}
                    />
                  )}
                </div>

                <div className="options">
                  {question.options.map((option, optionIndex) => {
                    let optionClass = "";

                    if (optionIndex === userAnswerIndex) {
                      optionClass = isCorrect ? "correct" : "incorrect";
                    }

                    return (
                      <p key={optionIndex} className={`option ${optionClass}`}>
                        {option[language]?.type === "text" ? (
                          option[language].content
                        ) : (
                          <img
                            src={`${apiurl}/${option[language].content}`}
                            alt={`Option ${optionIndex + 1}`}
                          />
                        )}
                      </p>
                    );
                  })}
                </div>

                <div className="selected-option">
                  <p>
                    <strong>Your Answer: </strong>
                    {isAnswered ? (
                      question.options[userAnswerIndex][language]?.type === "text" ? (
                        question.options[userAnswerIndex][language].content
                      ) : (
                        <img
                          src={`${apiurl}/${question.options[userAnswerIndex][language].content}`}
                          alt="Selected Answer"
                        />
                      )
                    ) : (
                      "Not Answered"
                    )}
                  </p>
                </div>

                <div className="correct-option">
                  <p>
  
     <strong>Correct Answer: </strong>
                    {question.options[correctAnswerIndex][language]?.type === "text" ? (
                      question.options[correctAnswerIndex][language].content
                    ) : (
                      <img
                        src={`${apiurl}/${question.options[correctAnswerIndex][language].content}`}
                        alt="Correct Answer"
                      />
                    )}
                  </p>
                </div>

                {/* Solution toggle button */}
                <button className="solution-toggle" onClick={() => toggleSolution(index)}>
                  {visibleSolutions[index] ? "Hide Solution" : "Show Solution"}
                </button>
                {visibleSolutions[index] && (
                  <div className="solution-text">
                    {question.solution[language]?.type === "text" ? (
                      <p>{question.solution[language].content}</p>
                    ) : (
                      <img
                        src={`${apiurl}/${question.solution[language].content}`}
                        alt={`Solution ${index + 1}`}
                      />
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TestResultPage;
