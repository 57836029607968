import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import apiurl from "../apiUrl.js";

import LoginSignup from "../Header/LoginSignup.js";

import "./examTypeList.css";

const ExamTypeList = () => {
  const { exam, examName } = useParams();
  const navigate = useNavigate();

  const [selectedTestType, setSelectedTestType] = useState("Full Test");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [testTypeData, setTestTypeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropTypeClick = (testType) => {
    setSelectedTestType(testType);
    setDropdownOpen(false); // Close dropdown after selecting an item
  };

  const openDialogList = () => {setIsFormDialogOpen(true);
    setDropdownOpen(false);
  }
  const closeDialogList = () => setIsFormDialogOpen(false);

  

  

  const fetchTestData = () => {
    if (exam && examName) {
      setIsLoading(true);
      axios
        .post(`${apiurl}/examtype/gettesttypedata`, { exam, examName })
        .then((response) => {
          
          setTestTypeData(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching test type data:", error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchTestData();
  }, [exam, examName]);
  console.log(testTypeData.time)

  const handleStartClick = (testId, testType, ttime) => {
    navigate(`/cbtexam/${exam}/${examName}/${testType.replaceAll(" ", "_")}`, { state: { id: testId, exam, examName, testType, ttime } });
    const token = localStorage.getItem("token");
    if (token) {
    }else{
    setIsDialogOpen(true);
    }
  };



  if (isLoading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  const sortedTestTypeData = testTypeData
    .filter((test) => test.testType.startsWith(selectedTestType))
    .sort((a, b) => {
      const aTestNumber = parseInt(a.testType.split(" ")[2]);
      const bTestNumber = parseInt(b.testType.split(" ")[2]);
      return aTestNumber - bTestNumber;
    });

  return (
    <>

   

      
      <div className="testtypeheader">
        {[
          "Full Test",
          "Section Test",
          "Chapter Test",
          "Quiz",
          "Previous Year",
          "Notes",
        ].map((testType) => (
          <div
            key={testType}
            className={`testtype-item ${
              selectedTestType === testType ? "selected" : ""
            }`}
            onClick={() => setSelectedTestType(testType)}
          >
            {testType}
          </div>
        ))}
      </div>

      <div className="selectBtn">
      <button
          className="dropdown-button"
          onClick={() => { setDropdownOpen(!dropdownOpen);}} >
          Select Test Type
        </button>

      </div>

      {dropdownOpen && (
          <div className="dropdown-menu">
            {["Full Test", "Section Test", "Chapter Test", "Quiz", "Previous Year", "Notes"].map((testType) => (
              <div
                key={testType}
                className={`dropdown-item ${
                  selectedTestType === testType ? "selected" : ""
                }`}
                onClick={() => handleDropTypeClick(testType)}
              >
                {testType}
              </div>
            ))}
          </div>
        )}

      <div className="examTypeListSect">
  {sortedTestTypeData.length > 0 ? (
    sortedTestTypeData.map((test) => (
      <div key={test._id} className="examTypeListCard">
        <h3>
          {test.examName.replaceAll("_", " ").toUpperCase()} - {test.testType}
        </h3>

        <div>
          <span>Total Marks: {test.marks}</span> | 
          <span> Total Questions: {test.numberOfQuestions}</span>
          <span> Total Time: {test.time}</span>
        </div>

        <div>
          <span>Language(s): {test.language.join(", ")}</span> | 
          <span> Syllabus: {test.syllabus}</span>
        </div>

        <button onClick={() => handleStartClick(test._id, test.testType, test.time)}>
          Go For Exam
        </button>
      </div>
    ))
  ) : (
    <p>No tests available for this test type.</p>
  )}
</div>


      <LoginSignup
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default ExamTypeList;
