import React, { useState } from 'react'; 
import axios from 'axios';
import "./loadUniExam.css";
import { useParams } from "react-router-dom";
import apiurl from '../apiUrl';

const ExamSelector = () => {
  const { universityName } = useParams();
  const [examType, setExamType] = useState('');
  const [examName, setExamName] = useState('');
  const [semester, setSemester] = useState('');
  const [isExamNameEnabled, setIsExamNameEnabled] = useState(false);
  const [isSemesterEnabled, setIsSemesterEnabled] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false); // Initially hidden form

  const examTypes = ['Previous Exam', 'Guess Paper', 'Entrance Exam'];
  
  const examSemesters = {
    'B.A': ['1st', '2nd', '3rd', '4th'],
    'B.Sc': ['1st', '2nd', '3rd', '4th'],
    'B.Com': ['1st', '2nd', '3rd', '4th'],
  };

  const examNames = Object.keys(examSemesters);

  const handleExamTypeChange = (e) => {
    setExamType(e.target.value);
    setIsExamNameEnabled(true);
    setExamName('');
    setSemester('');
    setIsSemesterEnabled(false);
  };

  const handleExamNameChange = (e) => {
    const selectedExamName = e.target.value;
    setExamName(selectedExamName);
    setIsSemesterEnabled(true);
    setSemester('');
  };

  const handleSemesterChange = (e) => {
    setSemester(e.target.value);
  };

  const handleSearchToggle = () => {
    setIsFormVisible(!isFormVisible); // Toggle form visibility
  };

  const handleSearch = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await axios.get(`${apiurl}/uni/getfiles`, {
        params: {
          examType,
          examName,
          semester,
          university: universityName,
        },
      });

      if (response.data.files) {
        setData(response.data.files);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setErrorMessage("No data found");
      } else {
        setErrorMessage("An error occurred while fetching data");
      }
      setData([]);
    } finally {
      setLoading(false);
    }

    setIsFormVisible(false); // Hide form after search
  };

  const handleDownload = async (filename) => {
    try {
      console.log(`Downloading: ${filename}`);
      const response = await fetch(`${apiurl}/unidata/download/${filename}`, {
        method: "GET",
      });
  
      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }
  
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
  
      // Create a temporary link to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
  
      // Clean up resources
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
  
      console.log(`${filename} downloaded successfully!`);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  
  return (
    <div className="exam-selector-container">
      <h1>{universityName} Exams</h1>

      {/* Toggle Button for Search Form */}
      <button className="toggle-form-button" onClick={handleSearchToggle}>
        {isFormVisible ? 'Hide Search Form' : 'Search'}
      </button>

      {/* Form is displayed when isFormVisible is true */}
      {isFormVisible && (
        <div className="select-row">
          <select value={examType} onChange={handleExamTypeChange}>
            <option value="">Type of Exam</option>
            {examTypes.map((type, index) => (
              <option key={index} value={type}>{type}</option>
            ))}
          </select>

          <select
            value={examName}
            onChange={handleExamNameChange}
            disabled={!isExamNameEnabled}
          >
            <option value="">Exam Name</option>
            {examNames.map((name, index) => (
              <option key={index} value={name}>{name}</option>
            ))}
          </select>

          <select
            value={semester}
            onChange={handleSemesterChange}
            disabled={!isSemesterEnabled}
          >
            <option value="">Semester</option>
            {examName && examSemesters[examName] && examSemesters[examName].map((sem, index) => (
              <option key={index} value={sem}>{sem}</option>
            ))}
          </select>

          <button onClick={handleSearch} disabled={!semester}>Search</button>
        </div>
      )}

      {loading && <div className="loader">Loading...</div>}

      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <div className="results">
        {data && data.length > 0 ? (
          data.map((file, index) => (
            <div key={index} className="result-item">
              <h2>{`${file.examName}`}</h2>
              <span>{index + 1}. {`${file.subjectName} ${file.semester} ${file.year}`}</span>
              <button onClick={() => handleDownload(file.fileName)}>Download</button>
            </div>
          ))
        ) : (
          <p>{errorMessage || "No results found"}</p>
        )}
      </div>
    </div>
  );
};

export default ExamSelector;
