import React, {useRef, useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';  // Import Link from react-router-dom
import './govtExamStyle.css';

import apiurl from '../apiUrl.js';

const stateCat = [{ icon: "🏛️", name: "State Govt. Exams" }]
const categories = [
  { icon: "🏛️", name: "State Govt. Exams" },
  { icon: "🏛️", name: "StateWise CET Exams" },
  { icon: "👔", name: "SSC Exams" },
  { icon: "🏦", name: "Banking Exams" },
  { icon: "👨‍🏫", name: "Teaching Exams" },
  { icon: "🕴️", name: "Civil Services Exams" },
  { icon: "🚆", name: "Railways Exams" },
  { icon: "🧰", name: "Engineering Recruitment Exams" },
  { icon: "🛡️", name: "Defence Exams" },
  { icon: "🚓", name: "Police Exams" },
  { icon: "💼", name: "Insurance Exams" },
  { icon: "🏥", name: "Nursing Exams" },
  { icon: "⚖️", name: "Other Govt. Exams" },
];

const sscexams = [
  "SSC CGL", "SSC GD Constable", "SSC CHSL", "SSC MTS",
  "SSC CPO", "SSC Selection Post", "SSC Stenographer", 
  "Delhi Police Constable", "SSC JE CE", "SSC JE EE",
  "SSC JE ME", "SSC JHT", "CSIR ASO", "IB ACIO",
  "RSMSSB JE", "IB Security Assistant", "Supreme Court Junior Assistant",
  "SSC Scientific Assistant", "Delhi Forest Guard", "NBE",
  "SSC Havaldar", "ICMR Assistant", "SSC JE", 
  "Supreme Court Law Clerk", "Delhi Police Head Constable",
  "NTA Delhi University", "Delhi Police Driver", "Delhi Police MTS"
];

const stateImages = [];

const cetExams = ["Maharashtra Exam", "Rajasthan CET", "Karnataka CET", "Uttar Pradesh CET", "Delhi CET",
  "Haryana CET", "Bihar CET", "Tamil Nadu CET"  ]

const bankingExams = [
  "IBPS PO", "IBPS Clerk", "IBPS RRB Officer Scale I, II, III",
  "IBPS RRB Office Assistant", "SBI PO", "SBI Clerk", "SBI SO",
  "RBI Grade B", "RBI Assistant", "NABARD Grade A & B",
  "NABARD Development Assistant", "LIC AAO", "LIC ADO",
  "NIACL AO", "NIACL Assistant"
];

const railwaysExams = [
  "RRB NTPC", "RRB Group D", "RRB ALP", "RRB JE", "RRB SSE",
  "RRB ASM", "RRB Technician", "RPF Constable", "RPF SI",
  "RRB Ministerial and Isolated Categories", "RRB Paramedical",
  "RRB Pharmacist", "RRB Staff Nurse", "RRB Traffic Assistant",
  "RRB Commercial Apprentice"
];

const stateExams = [
  "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", 
    "Punjab", "Rajasthan"];

const stateExamList = {
  
  bihar: ["BPSC", "Bihar STET", "Bihar Police Exam"],
  chhattisgarh: ["CGPSC", "CGVYAPAM", "Chhattisgarh TET"],
  goa: ["GPSC", "Goa TET"],
  gujarat: ["GPSC", "Gujarat TET", "Gujarat High Court Exam"],
  haryana: ["HPSC", "HTET", "Haryana Judiciary Exam"],
  }


  const CenterExam = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showSubSidebar, setShowSubSidebar] = useState(false);
    const [subDropdownOpen, setSubDropdownOpen] = useState(false);
    const [selectedState, setSelectedState] = useState(null);
    const [examList, setExamList] = useState([]); // State to store the current exam list
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const navigate = useNavigate();
  
    // let selectedState = null; 
  
    // Function to handle category selection
    const handleCategoryClick = (category) => {
      setSelectedCategory(category);

      if (category === "State Govt. Exams") {
        setShowSubSidebar((prev) => !prev);
           // Close main dropdown
      } else {
        updateExamList(category); // Update the exam list for other categories        
        setShowSubSidebar(false);
        }

    };

    const handledropCategoryClick = (category) => {
      setSelectedCategory(category);
  
      if (category === "State Govt. Exams") {
        setSubDropdownOpen((prev) => !prev);
           // Close main dropdown
      } else {
        updateExamList(category); // Update the exam list for other categories
          setSubDropdownOpen(false); // Close sub-dropdown if not needed
          setDropdownOpen(false); // Close main dropdown
        }
        
      };
      
      const handleStateSelection = (state) => {
        setSelectedState(state); // Set the selected state
        setExamList(stateExamList[state.toLowerCase()] || []); // Update exam list based on state
        setSubDropdownOpen(false); // Close the sub-dropdown
        setDropdownOpen(false); // Close main dropdown
  };
  
  
    // Function to handle state selection
    const handleStateClick = (state) => {
      setSelectedState(state);
      
      setExamList(stateExamList[state.toLowerCase()] || []);
      setShowSubSidebar((prev) => !prev);
    };
  
    // Function to get the exam list based on the selected category and state
    const getExamList = (category) => {
      switch (category) {
        case "SSC Exams":
          return sscexams;
        case "Banking Exams":
          return bankingExams;
        case "Railways Exams":
          return railwaysExams;
        case "StateWise CET Exams":
          return cetExams;
        case "State Govt. Exams":
          if (selectedState) {
            
            return stateExamList[selectedState.toLowerCase()] || [];
          }
          
          return [];
        default:
          return [];
      }
    };
  
    // Function to update the exam list state
    const updateExamList = (category) => {
      const newExamList = getExamList(category);
      setExamList(newExamList);
      
    };
  
    useEffect(() => {
      // Attach event listeners for state buttons
      document.addEventListener("DOMContentLoaded", () => {
        const stateButtons = document.querySelectorAll(".state-button");
        stateButtons.forEach((button) => {
          button.addEventListener("click", () => handleStateClick(button.dataset.state));
        });
      });
    }, []);

    const handleExamNavigation = (category, examType) => {
      
        // Navigate to the exam page
        navigate(`/cbtexam/${category}/${examType}`, {
          state: { category: selectedCategory, exam: examType },
    })
    };
    


  
    return (
      <div className="govtexam-contG_E">
       <div className="dropdown-contG_E">
    {/* Main Dropdown */}
    <button onClick={() => setDropdownOpen(!dropdownOpen)} className="dropdown-buttonG_E">
        Select Category
    </button>
    {dropdownOpen && (
        <div className="dropdown-menuG_E">
            {categories.map((category, index) => (
                <div
                    key={index}
                    className="dropdown-itemG_E"
                    onClick={() => handledropCategoryClick(category.name)}
                >
                    {category.icon}
                     {category.name.replace("Exams", "")}
                </div>
            ))}
        </div>
    )}

    {/* Sub-Dropdown for States */}
    {subDropdownOpen && (
        <div className="sub-dropdown-menuG_E">
            {stateExams.map((state, index) => (
                <div
                    key={index}
                    className="sub-dropdown-itemG_E"
                    onClick={() => handleStateSelection(state)}
                >
                    {state}
                </div>
            ))}
        </div>
    )}
          </div>

        {/* Sidebar */}
        <div className="sideGovtG_E1">
          {categories.map((category, index) => (
            <div
              key={index}
              className="sidebar-itemGovtG_E"
              onClick={() => handleCategoryClick(category.name)}
            >
              <span className="iconG_E">{category.icon}</span>
              <span className="nameG_E">{category.name}</span>
              <span className="arrowG_E">➡️</span>
              </div>
              ))}
              
              {showSubSidebar && (
                <div className="subStateExamGovtG_E1">
                  <ul>
                    {stateExams.map((exam, idx) => (
                      <li key={idx} onClick={() => handleStateClick(exam)}>
                        {exam}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            
          
        </div>
  
        {/* Exam List */}
       <div className="exam-gridG_E" id="exam-list">
  {selectedCategory ? (
    examList.length > 0 ? (
      examList.map((examType, index) => (
        <div key={index} className="exam-cardG_E">
          <h3>{examType}</h3>
          <p>Full Tests:</p>
          <p>Section Tests:</p>
          <button
            onClick={() =>
              handleExamNavigation(
                selectedCategory.replaceAll(" ", "_").toLowerCase(),
                examType.replaceAll(" ", "_").toLowerCase()
              )
            }
            className="exam-itemG_E"
          >
            <span>View Exams ➡️</span>
          </button>
        </div>
      ))
    ) : (
      <div className="no-selectionG_E">
        <p>No exams found for this category or state.</p>
      </div>
    )
  ) : (
    <div className="no-selectionG_E">
      {selectedCategory === "State Govt. Exams" && !selectedState ? (
        <p>Select a state to view its exams</p>
      ) : (
        <p>Select a category to view exams</p>
      )}
    </div>
  )}
</div>



      </div>
    );
  };
export default CenterExam;
