import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './TestPage.css';
import apiurl from '../apiUrl';

const TestPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const sidebarRef = useRef(null);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [markedQuestions, setMarkedQuestions] = useState({});
  const [language, setLanguage] = useState("en");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  

  const { examName, testName, testData, initialTime } = state || {};
  const [timeLeft, setTimeLeft] = useState(initialTime || 0);

  // Format time (hours:minutes:seconds)
  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // Handle option selection
  const handleOptionSelect = (optionIndex) => {
    setSelectedOptions({
      ...selectedOptions,
      [currentQuestionIndex]: optionIndex,
    });
    setMarkedQuestions({
      ...markedQuestions,
      [currentQuestionIndex]: true,
    });
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < testData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleClearResponse = () => {
    const updatedSelectedOptions = { ...selectedOptions };
    delete updatedSelectedOptions[currentQuestionIndex];
    setSelectedOptions(updatedSelectedOptions);

    const updatedMarkedQuestions = { ...markedQuestions };
    delete updatedMarkedQuestions[currentQuestionIndex];
    setMarkedQuestions(updatedMarkedQuestions);
  };

  const handleSubmit = () => {
    navigate('/test-result', {
      replace: true,
      state: { selectedOptions, questionsData: testData, examName, testName },
    });
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (testData) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [testData]);

  useEffect(() => {
    if (timeLeft === 0) {
      handleSubmit();
    }
  }, [timeLeft]);

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  if (!testData) {
    return <div>No test data available</div>;
  }

  const currentQuestion = testData[currentQuestionIndex];

  return (
    <div className="test-page">
      <header className="test-header">
        <h2>Exam: {examName}</h2>
        <h3>Timer: {formatTime(timeLeft)}</h3>
        <h3>Test: {testName}</h3>

        <select value={language} onChange={(e) => setLanguage(e.target.value)} className="language-select">
          <option value="en">English</option>
          <option value="hindi">Hindi</option>
        </select>
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          {isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}
        </button>
      </header>

      <div className="test-content">
        <div className="question-area">
          <div className="question-header">
            <h4>Question No. {currentQuestionIndex + 1}</h4>
          </div>
          <div className="question-text">
              {currentQuestion.question[language].type === "text" ? (
                <p>{currentQuestion.question[language].content}</p>
              ) : (
                
                <img
                  className="question-image"
                  src={`${apiurl}/${currentQuestion.question[language].content}`}
                  alt={`Question ${currentQuestionIndex + 1}`}
                />
              )}
            </div>
            <div className="options">
              {currentQuestion.options.map((option, index) => (
                <button
                  key={index}
                  className={`option ${
                    selectedOptions[currentQuestionIndex] === index ? "selected" : ""
                  }`}
                  onClick={() => handleOptionSelect(index)}
                >
                  {option[language].type === "text" ? (
                    option[language].content
                  ) : (
                    <img
                      className="option-image"
                      src={`${apiurl}/${option[language].content}`}
                      alt={`Option ${index + 1}`}
                    />
                  )}
                </button>
              ))}
            </div>


          <div className="question-controls">
            <button onClick={handlePreviousQuestion} className="back-button">
              Back
            </button>
            <button onClick={handleNextQuestion} className="next-button">
              Save & Next
            </button>
            <button className="clear-button" onClick={handleClearResponse}>
              Clear Response
            </button>
          </div>
        </div>

        <aside ref={sidebarRef} className={`sidebarQuest ${isSidebarOpen ? "open" : ""}`}>
          <div className="profile-info">
            <p>User: Suraj</p>
          </div>
          <div className="question-list">
            {testData.map((_, index) => (
              <button
                key={index}
                className={`question-number ${markedQuestions[index] ? 'marked' : ''}`}
                onClick={() => setCurrentQuestionIndex(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <button className="submit-button" onClick={handleSubmit}>
            Submit Test
          </button>
        </aside>
      </div>
    </div>
  );
};

export default TestPage;
