import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Navbar2 } from './Components/Header/Navbar2';
import Home from './Components/home';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import CenterExam from './Components/onlineExam/govtExam.js';
import  UniExam  from './Components/uniExam/uniExam2.js';
import ExamTypeList from './Components/examTypeList/examTypeList';
import TestPage from './Components/examStart/testStart';
import TestResultPage from './Components/examStart/TestResultPage';
import BriefTest from './Components/examStart/briefTest';
import Demo from './Components/Demo/demoFile';
import ExamSelector from './Components/uniExam/loadUniExam';
import UniversityExam from './Components/uniExam/uniPageFromHTML';
import Quiz from './Components/Quiz/quiz.js'

import CurrentAffair from './Components/CurrentAffair/currentAffair3.js';




function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

// Separate component to handle Navbar2 conditional rendering based on path
const MainContent = () => {
  const location = useLocation();
  
  // Check if the current path is "/testStart"
  // const showNavbar = location.pathname !== "/test/";
  const showNavbar = !location.pathname.startsWith("/test/");

  return (
    <>
      {showNavbar && <Navbar2 />}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route
          path='/cbtexam'
          element={<div style={{ marginTop: '3rem' }}><CenterExam /></div>} 
        />
        <Route
          path='/university'
          element={<div style={{ marginTop: '3rem' }}> <UniExam /></div>} 
        />
        <Route 
          path="/cbtexam/:exam/:examName" 
          element={<div style={{ marginTop: '3rem' }}> <ExamTypeList /></div>} 
        />
        <Route 
          path="/cbtexam/:exam/:examName/:testName" 
          element={<div style={{ marginTop: '3rem' }}> <BriefTest /></div>} />

        <Route 
          path="/quiz/:examName/brief" 
          element={<div style={{ marginTop: '3rem' }}> <BriefTest /></div>} />
          
        <Route path="/test/:testtype" element={<TestPage />} />
        <Route path="/test-result" element={<div style={{ marginTop: '3rem' }}> <TestResultPage /></div>} />


        <Route path="/quiz" element={<div style={{ marginTop: '3rem' }}> <Quiz /></div>} />

        <Route path='/university/:universityName' element={<div style={{ marginTop: '3rem' }}> <ExamSelector /></div>} />

        <Route    path="/currentaffair" element={<div style={{ marginTop: '3rem' }}> <CurrentAffair /></div>}  />

        <Route path="*" element={<Home />} />

      </Routes>
    </>
  );
};

export default App;
