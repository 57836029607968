import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './briefTest.css';
import { fetchTestData } from '../services/testData'; // Assume fetchTestData handles API call

const BriefTest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {id, exam, examName, testType, ttime } = location.state || {};
  const [isChecked, setIsChecked] = useState(false);
  const [testData, setTestData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  console.log(id, exam, examName, testType, ttime);

  useEffect(() => {
    const getTestData = async () => {
      try {
        const data = await fetchTestData(exam, examName, testType); // Fetch data with examName and testName
        setTestData(data.data); // Set the test data
        
        setLoading(false); // Stop loading
      } catch (err) {if (err.response?.status === 401) {
        setError('Unauthorized access. Please log in again.');
      } else {
        setError('Failed to load test data');
      }
        setLoading(false);
      }
    };
  
    getTestData();
  }, [examName, testType]); 

  console.log(testData);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  console.log(ttime)
  const handleStartTestClick = () => {
      if (isChecked && testData) {
        const initialTime = ttime*60;
      navigate(`/test/${testType}`, { state: { examName, testType, initialTime, testData  } });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="briefTest-container">
      <h2>Instructions for {testType}</h2>
      <p>Welcome to the {testType} of the {examName} exam.</p>
      <p>Please read the following instructions carefully before starting the test:</p>
      <ul>
        <li>The test duration is specified; please complete all questions within the time limit.</li>
        <li>Ensure a stable internet connection during the test.</li>
        <li>Once you begin, do not refresh or close the test window, as it may lead to test submission.</li>
        <li>You are allowed to attempt each question only once.</li>
        <li>After finishing the test, you will be redirected to the results page.</li>
      </ul>

      <label>
        <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
        I agree to the terms and conditions
      </label>

      <button
        className="startTest-button"
        onClick={handleStartTestClick}
        disabled={!isChecked}
      >
        Start Test
      </button>
    </div>
  );
};

export default BriefTest;
