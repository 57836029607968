import React from "react";
import './home.css';

import headerImg from "./images/home2.png";
import reviewsImg from "./images/review1.png";
import contactImg from "./images/Contact1.png";

const Home =()=>{

    return(
            <>
                <div className="home-containerAdmin">
                {/* Header Section */}
                <div className="home-headerAdmin">
                    <img src={headerImg} alt="Header" className="header-imgAdmin" />
                </div>
                
                {/* Reviews Section */}
                <div className="home-reviewsAdmin">
                    <img src={reviewsImg} alt="Reviews" className="reviews-imgAdmin" />
                </div>
                
                {/* Contact Section */}
                <div className="home-contactAdmin">
                    <img src={contactImg} alt="Contact" className="contact-imgAdmin" />
                </div>
            </div>
            </>
    )
}

export default Home;