import React, { useState, useEffect } from "react";
import filter_logo from '../images/filter-logo.png';
import apiUrl from '../apiUrl.js'
import { useNavigate } from 'react-router-dom';





import './quizStyle.css';

const examLists = {
  general :['Math', 'Englishh', 'Hindi', 'Physics', 'Chemistry', 'Biology', 'Geography', 'Polity', 'Economic', 'History'], 
  ssc: ['CGL', 'GD', 'CPO', 'CHSL'],
  banking: ['IBPS PO', 'IBPS Clerk', 'SBI PO', 'SBI Clerk'],
  teaching: ['CTET', 'TET', 'UPTET', 'REET']
};

const Quiz = () => {
  const [quizForm, setQuizForm] = useState({
    date: "",
    examType: "",
    examName: "",
    numQuestions: "",
    numMarks: "",
    examTime: "",
    syllabus: "",
    language: [],
  });
  const [examNameOptions, setExamNameOptions] = useState([]); 
  const [quizDialogVisible, setQuizDialogVisible] = useState(false);
  const [quizData, setQuizData] = useState([]);
  const [filteredQuizzes, setFilteredQuizzes] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [examType, setExamType] = useState("all");
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });

  const navigate = useNavigate();

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setQuizForm((prev) => ({
        ...prev,
        language: checked
          ? [...prev.language, value]
          : prev.language.filter((lang) => lang !== value),
      }));
    } else {
      setQuizForm((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Submit quiz form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/quiz/list/create`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(quizForm),
      });
      const data = await response.json();
      if (response.ok) {
        alert("Quiz created successfully!");
        fetchQuizzes(); // Refresh quizzes
      } else {
        alert(data.message || "Failed to create quiz");
      }
    } catch (error) {
      console.error("Error creating quiz:", error);
    }
  };

  const fetchQuizzes = async (startDate, endDate, examType) => {
    try {
      const response = await fetch(
        `${apiUrl}/quiz/list/fetch?startDate=${startDate}&endDate=${endDate}&examType=${examType}`
      );
      const data = await response.json();
  
      // Sort data by date in descending order before setting states
      const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setQuizData(sortedData);
      setFilteredQuizzes(sortedData);
    } catch (error) {
      console.error("Error fetching quizzes:", error);
    }
  };
  
  

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const oneWeekAgo = new Date(new Date().setDate(new Date().getDate() - 7))
      .toISOString()
      .split("T")[0];

    setStartDate(oneWeekAgo);
    setEndDate(today);
    fetchQuizzes(oneWeekAgo, today, "all");
  }, []);

  const handleFetch = () => {
    if (startDate && endDate) {
      fetchQuizzes(startDate, endDate, examType);
    } else {
      alert("Please select both start and end dates!");
    }
  };

  // Filter quizzes
  const filterQuizzes = (type) => {
    const filtered = quizData
      .filter(
        (quiz) =>
          (!type || quiz.examType === type) &&
          (!dateRange.startDate || new Date(quiz.date) >= new Date(dateRange.startDate)) &&
          (!dateRange.endDate || new Date(quiz.date) <= new Date(dateRange.endDate))
      )
      .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date in descending order
  
    setFilteredQuizzes(filtered);
  };
  


  useEffect(() => {
    if (quizForm.examType) {
      setExamNameOptions(examLists[quizForm.examType] || []);
      setQuizForm((prev) => ({ ...prev, examName: "" })); // Reset examName
    }
  }, [quizForm.examType]);


  const handleAddQuestion = (quizId, examType, examName, ttime) => {
    console.log(quizId, examType, examName);
    const state = { id: quizId, exam: "quiz", examName: examType, testType: examName, ttime };
    navigate(`/quiz/${examName}/brief`, { state });
    console.log("Add question to quiz:", quizId);
  };
  
  
  
  
  

  return (
    <div className="quiz-body">
         
      
     

      {/* Quiz List */}
      <div className="quiz-show-box">


      <div className="quiz-list">
      <div className="quiz-list-head">
        <div className="list-select-box">
          <select className="list-type-select"  name="examType"  value={examType}
            onChange={(e) => setExamType(e.target.value)}
            >
              <option value="">Select Exam Type</option>
              <option value="general">General Exam</option>
              <option value="ssc">SSC Exam</option>
              <option value="banking">Banking Exam</option>
              <option value="teaching">Teaching Exam</option>
          </select>
        </div>

        <label>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
        <button onClick={handleFetch}>Go</button>

        <div className="filter-box" onClick={() => setIsBoxVisible(!isBoxVisible)}>
          <img src={filter_logo} alt="Filter" />
          <div
            className="select-quiz-box"
            style={{ display: isBoxVisible ? "block" : "none" }}
          >
            <h3 onClick={() => filterQuizzes()}>All Quiz</h3>
            <h3 onClick={() => filterQuizzes("general")}>General</h3>
          </div>
        </div>
      </div>

      <div className="quiz-list-show">
            <h2>Quizzes</h2>
            {filteredQuizzes.length > 0 ? (
              <div className="quiz-card-container">
                {filteredQuizzes.map((quiz, index) => (
                  <div className="quiz-card" key={index}>
                    <div className="quiz-details">
                      <h2>Date: {quiz.date}</h2>
                      <h3> {quiz.examType} {quiz.examName}  : {quiz.syllabus}</h3>
                      <p>Total Question: {quiz.numQuestions}, Total Time: {quiz.examTime} Mint.</p>
                    </div>
                    <div className="quiz-actions">
                      <button className="add-question-btn" onClick={() => handleAddQuestion(quiz._id, quiz.examType, quiz.examName, quiz.examTime)}>Start Quiz</button>
                      
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No quizzes found for the selected dates.</p>
            )}
          </div>



    </div>


      </div>
    </div>
  );
};

export default Quiz;
