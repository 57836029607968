
import React, {useState} from "react"
import { Link, useNavigate, useParams } from 'react-router-dom'; 
import "./UniversityExam.css";



const categories = [
    { icon: "🏛️", name: "Central University" },
    { icon: "🏛️", name: "Haryana University" },
    { icon: "👔", name: "Utter Pardesh University" },
    { icon: "🏦", name: "Bihar University" },
    // { icon: "👨‍🏫", name: "Teaching Exams" },
    // { icon: "🕴️", name: "Civil Services Exam" },
    // { icon: "🚆", name: "Railways Exams" },
    // { icon: "🧰", name: "Engineering Recruitment Exams" },
    // { icon: "🛡️", name: "Defence Exams" },
    // { icon: "🚓", name: "Police Exams" },
    // { icon: "💼", name: "Insurance Exams" },
    // { icon: "🏥", name: "Nursing Exams" },
    // { icon: "⚖️", name: "Other Govt. Exams" },
  ];

  const commonUniversities = ["IGNOU", "University of Delhi (DU)",  "Jawaharlal Nehru University (JNU)", "Banaras Hindu University (BHU)", "Anna University", 
    "Aligarh Muslim University (AMU)", "Jamia Millia Islamia (JMI)", "Osmania University", "Panjab University", "Amity University",  "Symbiosis International University", 
    "Birla Institute of Technology and Science (BITS)", ];

    const haryanaUniversities = [
        "Maharshi Dayanand University (MDU) - Rohtak",
        "Kurukshetra University - Kurukshetra",
        "Chaudhary Charan Singh Haryana Agricultural University - Hisar",
        "Guru Jambheshwar University of Science and Technology - Hisar",
        "Bhagat Phool Singh Mahila Vishwavidyalaya - Khanpur Kalan, Sonipat",
        "YMCA University of Science and Technology - Faridabad",
      ];

      const stateExams = {
        Haryana: [
          "Maharshi Dayanand University (MDU) - Rohtak",
          "Kurukshetra University - Kurukshetra",
          "Chaudhary Charan Singh Haryana Agricultural University - Hisar",
          
        ],
        UttarPradesh: [
          "University of Lucknow - Lucknow",
          "Banaras Hindu University (BHU) - Varanasi",
          "Aligarh Muslim University (AMU) - Aligarh",
          "Dr. Ram Manohar Lohia Avadh University - Faizabad",
          "Chaudhary Charan Singh University - Meerut",
          "Uttar Pradesh Technica"
        ]}
      
      



 const UniExam =()=>{
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showSubSidebar, setShowSubSidebar] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const navigate = useNavigate();
  
    const handleCategoryClick = (category) => {
      setSelectedCategory(category);
      setShowSubSidebar(category === "State Govt. Exams");
    };

    const handleCategoryChange = (e) => {
      const selectedValue = e.target.value;
      setSelectedCategory(selectedValue);
    };
  
    const getExamList = () => {
      switch (selectedCategory) {
        case "Central University":
          return commonUniversities;
        case "Haryana University": return haryanaUniversities;
        
        default:
          return [];
      }
    };

    const handleNavigation = (universityName) => {
        navigate(`/university/${universityName}`);
    };
    
  
    return (
      <div className="uniexam-container">
        {/* Sidebar */}
        <div className="side-uni">
        {categories.map((category, index) => (
            <div
              key={index}
              className="uni-sidebar-item"
              onClick={() => handleCategoryClick(category.name)}
              // onMouseEnter={() => setShowSubSidebar(category.name === "State Govt. Exams")}
              // onMouseLeave={() => setShowSubSidebar(false)}
            >
              <span className="icon">{category.icon}</span>
              <span className="name">{category.name}</span>
              <span className="arrow">➡️</span>
  
              {/* Conditional rendering of sub-sidebar */}
              {/* {category.name === "State Govt. Exams" && showSubSidebar && (
                <div className="subStateExam">
                  <ul>
                    {stateExams.map((exam, idx) => (
                      <li key={idx}>{exam}</li>
                    ))}
                  </ul>
                </div>
              )} */}
            </div>
          ))}
        </div>

         {/* Dropdown for Categories */}
         <div className="dropdown-uni">
 
              <select
                className="uni-dropdown"
                onChange={handleCategoryChange}
                defaultValue=""
              >
                <option value="" disabled>
                  Select a Category
                </option>
                {categories.map((category, index) => (
                  <option key={index} value={category.name}>
                    {category.icon} {category.name}
                  </option>
                ))}
              </select>
            </div>

  
        {/* Exam list based on selected category */}
        <div className="exam-grid-uni">
            {selectedCategory ? (
              getExamList().map((universityName, index) => (
                <div key={index} className="exam-item-container">
                  <h3>{universityName}</h3>
                  <button
                    className="exam-item"
                    onClick={() => handleNavigation(universityName)}
                  >
                    <span>➡️</span>
                  </button>
                </div>
              ))
            ) : (
              <div className="no-selection">
                <p>Select a category to view exams</p>
              </div>
            )}
          </div>

  

      </div>
    );
  };


  export default UniExam;